import React from 'react';
import { makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

//IMAGES
import bgHeader from '../assets/images/bgHeader.webp';
// import bgHeader940 from '../assets/images/bgHeader940.webp';
// import bgHeader1440 from '../assets/images/bgHeader.webp';


const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  HeaderContent: {
    height:'800px',
  },
  bgHeader: {
    position: 'absolute',
    top: '0',
    width: '100%',
    zIndex: '-1',
    [theme.breakpoints.down('xs')]: {
      height:'800px',
      objectFit:'cover',
    }
  },
  white: {
    color: theme.palette.common.white,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  button: {
    backgroundColor: 'secondary',
    padding: '0.75rem 4rem',
    marginTop: '3rem',
    color: 'white',
    boxShadow: 'none',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
  },
  alignSelf: {
    alignSelf: 'flex-end',
  },
  arrowDown: {
    fontSize: '2rem',
    marginTop: '1rem',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  return (
    <Grid className={classes.header}>
      <Box display={{ xs: 'block', sm: 'block', md: 'block' }}>
        <img src={bgHeader} alt='Axem' className={classes.bgHeader} />
      </Box>
      {/* <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
        <img src={bgHeader940} alt='Logo' className={classes.bgHeader} />
      </Box>
      <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
        <img src={bgHeader1440} alt='Logo' className={classes.bgHeader} />
      </Box> */}
      <Container>
        <Box display='flex' justifyContent='center' alignItems='center' textAlign='center' flexWrap='wrap' className={classes.HeaderContent}>
          <Grid item sm={10} md={8} className={classes.alignSelf}>
            <Box mb={{xs:4}}>
              <Typography variant='body1' component='p' className={classes.white}>
                Seja bem vindo!
              </Typography>
            </Box>
            <Box mb={{xs:4}}>
              <Typography variant='h1' component='h1' className={classes.white}>
                Nós somos a <span className={classes.secondary}>Axem</span><br/> Engenharia e Arquitetura
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={10} md={8} className={classes.alignSelf}>
            <Box pb={{xs:20}}>
              <Typography variant='body1' component='p' className={classes.white}>
                Entenda como podemos te ajudar a tirar seus projetos do papel.
              </Typography>
              <ArrowDownwardIcon className={`${classes.arrowDown} ${classes.secondary}`} />
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  );
}
