import React from 'react';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core';
import Routes from './components/Routes';

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: 'white',
  },
}));

const theme = createTheme ({
  root: {
    height: '100vh',
    backgroundColor: 'white',
  },
  spacing: 4,
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      grey: '#E5E5E5',
      grey2: '#ECEFF6',
    },
    primary: {
      main: '#2B3990',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00BFC0',
    },
  },
  // typography: {
  //   fontFamily: [
  //     'Open Sans',
  //   ].join(','),
  //   h4: {
  //     fontSize: '1.5rem',
  //     lineHeight: '1.65rem',
  //     marginBottom: '1rem',
  //     fontFamily: 'brandon-grotesque , sans-serif',
  //   },
  //   h6: {
  //     fontSize: '1rem',
  //     lineHeight: '1.375rem',
  //     fontFamily: 'brandon-grotesque , sans-serif',
  //     fontWeight: '700',
  //   },
  //   body1: {
  //     color: '#96A7C6',
  //     fontSize: '1.125',
  //     lineHeight: '1.5rem',
  //     fontFamily: 'brandon-grotesque , sans-serif',
  //   },
  //   body2: {
  //     color: '#96A7C6',
  //     fontSize: '1rem',
  //     lineHeight: '1.5rem',
  //     fontFamily: 'brandon-grotesque , sans-serif',
  //   },
  // },
});

theme.typography.h1 = {
  fontSize: '2.875rem',
  lineHeight: '3.25rem',
  marginBottom: '2rem',
  fontFamily: 'Montserrat , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '3rem',
  },
};
theme.typography.h2 = {
  fontSize: '2rem',
  lineHeight: '2.5rem',
  marginBottom: '2rem',
  fontFamily: 'Montserrat , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
};
theme.typography.h3 = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginBottom: '2rem',
  fontFamily: 'Montserrat , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '0rem',
  },
};
theme.typography.h4 = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginBottom: '2rem',
  fontFamily: 'brandon-grotesque , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '0rem',
  },
};
theme.typography.h5 = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginBottom: '1rem',
  fontFamily: 'brandon-grotesque , sans-serif',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.75rem',
  },
};
theme.typography.body1 = {
  color: '#96A7C6',
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  fontFamily: 'brandon-grotesque , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
  },
};
theme.typography.body2 = {
  color: '#96A7C6',
  fontSize: '1.025rem',
  lineHeight: '1.25rem',
  fontFamily: 'brandon-grotesque , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};

function App() {

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Routes />
      </div>
    </ThemeProvider>
  );

}

export default App;
