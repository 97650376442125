import React from 'react';
import Header from './Header';
import TopBarHome from './TopBarHome';
import {
   makeStyles, Container, Typography, Grid, Box, Link,
   List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import Card from '../components/Card';
import CarouselPortfolio from '../components/CarouselPortfolio';

// Icons
import { Brightness1, Facebook, Instagram, LinkedIn, Phone, Mail, Place } from '@material-ui/icons';
// import { WhatsApp } from '@material-ui/icons';

// Images
import Logo from '../assets/images/logo.webp';
import Bullets from '../assets/images/bullets.webp';
import Areas from '../assets/images/areas-de-atuacao.webp';
import Atividades from '../assets/images/atividades.webp';
import Segmentos from '../assets/images/segmentos-de-atuacao.webp';
import Mapa from '../assets/images/mapa.webp';
import Clientes from '../assets/images/clientes.webp';
import Albery from '../assets/images/albery.webp';
import Thais from '../assets/images/thais.webp';
import Lopes from '../assets/images/lopes.webp';
import Maia from '../assets/images/maia.webp';

const useStyles = makeStyles((theme) => ({
   dark: {
      color: theme.palette.common.black,
      fontWeight: '400',
   },
   white: {
      color: theme.palette.common.white,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   // grey: {
   //    color: theme.palette.common.grey,
   // },
   grey2: {
      color: theme.palette.common.grey2,
   },
   fontBold: {
      fontWeight: 'bold',
   },
   img: {
      width: '100%',
   },
   button: {
      backgroundColor: 'secondary',
      padding: '0.75rem 4rem',
      color: 'white',
      boxShadow: 'none',
      borderRadius: '4px',
      fontFamily: 'Roboto',
      fontWeight: 'Bold',
      margin: '0 .5rem',
      width: '35%',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
         width: '100%',
         margin: 0,
         marginTop: '1rem',
      },
   },
   titleBg: {
      fontSize: '5.5rem',
      opacity: '0.5',
      fontFamily: 'Montserrat , sans-serif',
      fontWeight: '800',
      lineHeight: '35px',
      position: 'relative',
      letterSpacing: '-1px',
      [theme.breakpoints.down('sm')]: {
         lineHeight: '50px',
         fontSize: '2.5rem',
      },
   },
   bgGrey: {
      backgroundColor: theme.palette.common.grey2,
      paddingBottom: '5rem',
   },
   bgAzul: {
      backgroundColor: '#2B3990',
   },
   highlighter: {
      color: theme.palette.primary.main,
      marginBottom: '1rem',
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
   },
   iconWhite: {
      color: theme.palette.secondary.main,
      fontSize: '0.75rem',
   },
   iconRedes: {
      marginTop: '0.25rem',
      marginRight: '1.25rem',
      fontSize: '1.25rem',
      float: 'left',
      color: theme.palette.secondary.main,
   },
   listItemIcon: {
      minWidth: '1.75rem',
   },
   listItem: {
      paddingTop: '0.05rem',
      paddingBottom: '0.05rem',
   },
   listItemAbout: {
      padding: '0.05rem 0',
   },
   listItemText: {
      fontSize: '1.1rem',
      color: theme.palette.common.grey,
   },
   menuItem: {
      display: 'block',
      color: theme.palette.common.white,
      lineHeight: '1.75rem',
      // transitionDuration: '400ms',
      textDecoration: 'none',
      '&:focus, &:hover': {
         color: theme.palette.secondary.main,
         textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
         //   fontSize: '1.2rem',
         //   color: 'rgba(0,0,0,0.7)',
         '&:focus, &:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
         },
      },
   },
   hr: {
      height: '0',
      margin: '3rem 0',
      border: '1px solid rgba(236, 239, 247, 0.1)'
   },
   BoxAreas: {
      position: 'relative',
      // width: '395px',
      height: '500px',
      overflow: 'hidden',
   },
   BoxAtividades: {
      position: 'relative',
      // width: '395px',
      height: '500px',
      overflow: 'hidden',
   },
   BoxSegmentos: {
      position: 'relative',
      // width: '395px',
      height: '500px',
      overflow: 'hidden',
   },
   BoxImg: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: 'auto',
      height: '100%',
      zIndex: '0',
   },
   boxAbout: {
      position: 'absolute',
      top: '0',
      left: '0',
      opacity: '0.7',
      zIndex: '1',
      width: '100%',
      height: '100%',
      boxShadow: '0px 4px 30px rgb(0 0 0 / 6%)',
      borderRadius: '4px',
      backgroundColor: theme.palette.primary.main,
   },
   boxStaff: {
      width: '100%',
      height: '100%',
      minHeight: '320px',
      boxShadow: '0px 4px 30px rgb(0 0 0 / 6%)',
      borderRadius: '4px',
      backgroundColor: theme.palette.common.white,
   },
   rosterBox: {
      width: '150px',
      height: '150px',
      overflow: 'hidden',
      borderRadius: '4px',
      marginBottom: '1rem',
   },
   roster: {
      marginTop: '-1rem',
      filter: 'grayscale(100%)',
   },
   albery: {
      marginTop: '-3rem !important',
   },
   maia: {
      marginTop: '0rem !important',
   },
}));


// }));

export default function Home() {
   const classes = useStyles();
   return (
      <Grid>

         <TopBarHome />
         <Header />


         {/* Quem somos */}
         <Box my={24} px={{ xs: 4, md: 0 }} height='300px' id='quem-somos'>
            <Container>
               <Grid container justifyContent='space-between'>
                  <Grid item md={6}>
                     <Box className={`${classes.titleBg} ${classes.grey2}`} textAlign='center' >Quem somos</Box>
                     <Typography variant='h2' component='h2' className={classes.primary}>
                        <Box textAlign='center' mt={-4} position='relative' zIndex={1}>Quem somos</Box>
                        <Box textAlign='center' mt={-2}><img src={Bullets} alt='Bullets' /></Box>
                     </Typography>
                  </Grid>
                  <Grid item md={5}>
                     <Typography variant='h5' component='h3' className={classes.primary}>
                        Somos Engenharia, somos Arquitetura.
                     </Typography>
                     <Typography variant='body1' component='p'>
                        Somos uma empresa de projetos de arquitetura e engenharia criada em 2018 com propósito de oferecer
                        as melhores soluções técnicas, garantindo excelência, qualidade e otimização dos projetos.
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Áreas de atuação */}
         <Box className={classes.bgGrey}>
            <Container>
               <Grid container spacing={8}>
                  <Grid item md={4}>
                     <Box mt={{ xs: 16, md: -40 }} px={12} py={10} className={`${classes.BoxAreas}`}>
                        <Box className={classes.white} mb={{ xs: 0, md: -4 }} position='relative' zIndex={2}>
                           <Typography variant='h3' component='h3'>Áreas de atuação</Typography>
                        </Box>
                        <Box position='relative' zIndex={2}>
                           <List>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Arquitetura</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Instalações Elétricas (Média e Baixa Tensão)</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>SPDA e Aterramento</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Sistemas Eletrônicos</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Instalações Hidrossanitárias </Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Instalações de Gás</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Ar-Condicionado, Ventilação e Exaustão Mecânica</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Instalações de Proteção e Combate</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Contra Incêndio</Box></ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                        <Box className={`${classes.boxAbout}`}></Box>
                        <img src={Areas} className={classes.BoxImg} alt='Areas de atuação' width='100%' />
                     </Box>
                  </Grid>
                  <Grid item md={4}>
                     <Box mt={{ xs: 16, md: -40 }} px={12} py={10} className={`${classes.BoxAtividades}`}>
                        <Box className={classes.white} mb={{ xs: 0, md: -4 }} position='relative' zIndex={2}>
                           <Typography variant='h3' component='h3'>Atividades</Typography>
                        </Box>
                        <Box position='relative' zIndex={2}>
                           <List>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Estudo de Massas</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Estudos de Viabilidade</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Levantamentos Cadastrais</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Projeto Conceitual</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Estudo Preliminar</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Projeto Básico, Legal e Executivo</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Compatibilização de Projetos</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Análise de Consistência Técnica de Projetos</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>A.T.O. – Assistência Técnica à Obra</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Consultoria</Box></ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                        <Box className={`${classes.boxAbout}`}></Box>
                        <img src={Atividades} className={classes.BoxImg} alt='Atividades' width='100%' />
                     </Box>
                  </Grid>
                  <Grid item md={4}>
                     <Box mt={{ xs: 16, md: -40 }} px={12} py={10} className={`${classes.BoxSegmentos}`}>
                        <Box className={classes.white} mb={{ xs: 0, md: -4 }} position='relative' zIndex={2}>
                           <Typography variant='h3' component='h3'>Segmentos de atuação</Typography>
                        </Box>
                        <Box position='relative' zIndex={2}>
                           <List>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Edificações Residenciais, Comerciais, Corporativas, Saúde, Culturais, Educacionais, Científicas e Técnicas</Box></ListItemText>
                              </ListItem>
                              <ListItem className={classes.listItemAbout}>
                                 <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.iconWhite} /></ListItemIcon>
                                 <ListItemText><Box className={classes.listItemText}>Indústrias Leves (Alimentícia, Farmacêutica, Química, Equipamentos, Oficinas e Manutenção)</Box></ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                        <Box className={`${classes.boxAbout}`}></Box>
                        <img src={Segmentos} className={classes.BoxImg} alt='Segmentos de atuação' width='100%' />
                     </Box>
                  </Grid>
               </Grid>
               <Box className={`${classes.titleBg} ${classes.white}`} textAlign='right' pt={12} pb={{ xs: 0, md: 24 }}>Institucional</Box>
            </Container>
         </Box>

         {/* Missão, Visão e Valores */}
         <Box mt={{ xs: 16, md: -34 }}>
            <Container>
               <Grid container justifyContent='center' alignItems='center'>
                  <Grid item>
                     {/* <Box mt={{ xs: 10, md: 12 }} mb={0}> */}
                     <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                        <Card
                           col='4'
                           classHighlighter={classes.highlighter}
                           text='Missão'
                           text2='Promover a melhor experiência em projetos de arquitetura e engenharia, priorizando a inovação e qualidade contribuindo para um mundo melhor e sustentável.'
                        />
                        <Card
                           col='4'
                           classHighlighter={classes.highlighter}
                           text='Visão'
                           text2='Sermos uma empresa de reconhecimento nacional pela excelência dos nossos projetos.'
                        />
                        <Card
                           col='4'
                           classHighlighter={classes.highlighter}
                           text='Valores'
                           text2='Responsabilidade, Compromisso, Qualidade, Ética.'
                        />
                     </Grid>
                     {/* </Box> */}
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Profissionais */}
         <Box mt={24} mb={6} px={{ xs: 4, md: 0 }} height='' id='corpo-tecnico'>
            <Container>
               <Grid container justifyContent='space-between'>
                  <Grid item md={6}>
                     <Box className={`${classes.titleBg} ${classes.grey2}`} textAlign='center'>Profissionais</Box>
                     <Typography variant='h2' component='h2' className={classes.primary}>
                        <Box textAlign='center' mt={-4} position='relative' zIndex={1}>Nossos Especialistas</Box>
                        <Box textAlign='center' mt={-2}><img src={Bullets} alt='Bullets' /></Box>
                     </Typography>
                  </Grid>
                  <Grid item md={5}>
                     <Typography variant='h5' component='h3' className={classes.primary}>
                        + 15 anos construindo o futuro.
                     </Typography>
                     <Typography variant='body1' component='p'>
                        Juntos, nosso corpo técnico possui mais de 15 anos de experiência atuando em projetos hidrossanitários,
                        proteção e combate a incêndio, elétricos, especiais, gás natural, ar condicionado e arquitetura em ambientes
                        comerciais, residenciais, hospitalares, industriais de pequeno
                        e médio porte, hotéis, prédios históricos, entre outros.
                     </Typography>
                  </Grid>
               </Grid>
               <Box mt={18} mb={28}>
                  <Grid container justifyContent='space-between' alignItems='flex-end' textAlign='center' spacing={8}>

                     <Grid item md={6}>
                        <Box p={6} className={classes.boxStaff}>
                           <Grid container justifyContent='flex-start'>
                              <Grid item md={4}>
                                 <Box className={classes.rosterBox}>
                                    <img src={Albery} alt='Albery Barroco' width='150px' height='auto' className={`${classes.roster} ${classes.albery}`} />
                                 </Box>
                              </Grid>
                              <Grid item md={8}>
                                 <Box mb={{ xs: 0, md: -8 }}>
                                    <Typography variant='h3' component='h3' className={classes.primary}>Albery Barroco </Typography>
                                 </Box>
                                 <Box mb={8}>
                                    <Typography variant='body1' component='p' className={classes.primary}>Engenheiro Eletricista </Typography>
                                 </Box>
                                 <Typography variant='body2' component='p'>
                                    Graduado em Engenharia Elétrica com ênfase em Eletrotécnica, MBA em Gerenciamento de Projetos com mais de 23 anos de
                                    experiência na Engenharia, larga experiência no desenvolvimento de projetos de engenharia com trabalhos desenvolvidos
                                    fortemente na área Industrial, para organizações públicas e privadas, com atuação nos ramos imunobiológico (vacinas),
                                    farmacêutico, siderurgia, petroquímico e alimentícia, e atuações nos setores Hoteleiro, Corporativo, Comerciais e
                                    Eventos Esportivos.
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     
                     <Grid item md={6}>
                        <Box p={6} className={classes.boxStaff}>
                           <Grid container justifyContent='flex-start'>
                              <Grid item md={4}>
                                 <Box className={classes.rosterBox}>
                                    <img src={Thais} alt='Thais Aresta' width='150px' height='auto' className={classes.roster} />
                                 </Box>
                              </Grid>
                              <Grid item md={8}>
                                 <Box mb={{ xs: 0, md: -8 }}>
                                    <Typography variant='h3' component='h3' className={classes.primary}>Thais Aresta</Typography>
                                 </Box>
                                 <Box mb={8}>
                                    <Typography variant='body1' component='p' className={classes.primary}>Engenheira Civil</Typography>
                                 </Box>
                                 <Typography variant='body1' component='p'>
                                    Graduada em Engenharia Civil, pós-graduada em Engenharia Ambiental e Saneamentos Básico e pós-graduada em Gerenciamento
                                    de Projetos, especialista em Instalações Hidrossanitárias com mais de 15 anos de experiencia já tendo realizado projetos
                                    em todo território nacional no ramo educacional, corporativo, comercial, hospitalar, farmacêutico e hoteleiro.
                                 </Typography>

                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Box p={6} className={classes.boxStaff}>
                           <Grid container justifyContent='flex-start'>
                              <Grid item md={4}>
                                 <Box className={classes.rosterBox}>
                                    <img src={Lopes} alt='Vinicius Lopes' width='150px' height='auto' className={classes.roster} />
                                 </Box>
                              </Grid>
                              <Grid item md={8}>

                                 <Box mb={{ xs: 0, md: -8 }}>
                                    <Typography variant='h3' component='h3' className={classes.primary}>Vinícius Lopes</Typography>
                                 </Box>
                                 <Box mb={8}>
                                    <Typography variant='body1' component='p' className={classes.primary}>Arquitetura</Typography>
                                 </Box>
                                 <Typography variant='body1' component='p'>
                                    Graduado em Arquitetura e Urbanismo com mais de 15 anos de atuação na área da construção civil. Vasta experiência em projetos de arquitetura, prefeituras, arquitetura de interiores, atuando nas áreas residências, comerciais e hospitalares de órgãos públicos e privados.
                                 </Typography>

                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Box p={6} className={classes.boxStaff}>
                           <Grid container justifyContent='flex-start'>
                              <Grid item md={4}>
                                 <Box className={classes.rosterBox}>
                                    <img src={Maia} alt='Vinicius Maia' width='150px' height='auto' className={`${classes.roster} ${classes.maia}`} />
                                 </Box>
                              </Grid>
                              <Grid item md={8}>

                                 <Box mb={{ xs: 0, md: -8 }}>
                                    <Typography variant='h3' component='h3' className={classes.primary}>Vinicius Maia</Typography>
                                 </Box>
                                 <Box mb={8}>
                                    <Typography variant='body1' component='p' className={classes.primary}>Engenheiro Mecânico </Typography>
                                 </Box>
                                 <Typography variant='body1' component='p'>
                                    Graduado em Engenharia Mecânica, especialista em ar-condicionado e instalações mecânicas com mais de 10 anos de experiencia no seguimento de projetos, consultoria, fiscalização e gerenciamento de projetos de Ar-Condicionado tendo atuado em diversos segmentos como hospitalar, corporativo, comerciais e industriais.
                                 </Typography>

                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>

         {/* Portfolio */}
         <Container id='portfolio'>
            <Box className={`${classes.titleBg} ${classes.grey2}`} textAlign='right' mx={{ xs: 4, md: 0 }} pb={12}>Portfólio</Box>
            <Box px={{ xs: 4, md: 16 }} className={classes.bgAzul}>
               <CarouselPortfolio />
            </Box>
         </Container>

         {/* Áreas de Atuação */}
         <Box my={24} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='space-evenly'>
                  <Grid item md={5}>
                     <Box mb={4}>
                        <Box className={`${classes.titleBg} ${classes.grey2}`} textAlign='left'>Atuação</Box>
                        <Typography variant='h2' component='h2' className={classes.primary}>
                           <Box textAlign='left' mt={-4} position='relative' zIndex={1}>Onde atuamos</Box>
                           <Box textAlign='left' mt={-2}><img src={Bullets} alt='Bullets' /></Box>
                        </Typography>
                     </Box>
                     <Box>
                        <Grid container>
                           <Grid item md={10}>
                              <Box mb={8}>
                                 <Typography variant='body1' component='p'>
                                    Atuamos em todo território Nacional, tendo projetos concretizados nos seguintes estados
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item md={6}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Amazonas</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Pará</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Maranhão</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Pernambuco</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Paraíba</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Alagoas</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Sergipe</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Acre</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Ceará</ListItemText>
                                 </ListItem>
                              </List>
                           </Grid>
                           <Grid item md={6}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Bahia</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Distrito Federal</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Rio de Janeiro</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Goiás</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Minas Gerais</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>São Paulo</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Espírito Santo</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Rio Grande do Norte</ListItemText>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <ListItemIcon className={classes.listItemIcon}><Brightness1 className={classes.icon} /></ListItemIcon>
                                    <ListItemText>Rio Grande do Sul</ListItemText>
                                 </ListItem>
                              </List>
                           </Grid>
                        </Grid>
                     </Box>
                  </Grid>
                  <Grid item md={5}>
                     <img src={Mapa} alt='Mapa' width='100%' />
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Principais clientes */}
         <Box className={classes.bgGrey} py={{ xs: 8, md: 32 }} id='clientes'>
            <Container>
               <Grid item md={12}>
                  <Box className={`${classes.titleBg} ${classes.white}`} textAlign='center'>Principais Clientes</Box>
                  <Typography variant='h2' component='h2' className={classes.primary}>
                     <Box textAlign='center' mt={0} position='relative' zIndex={1}>Principais Clientes</Box>
                     <Box textAlign='center' mt={-2}><img src={Bullets} alt='Bullets' /></Box>
                  </Typography>
               </Grid>
               <Grid item md={12}>
                  <Box mt={16} textAlign='center'>
                     <img src={Clientes} alt='Clientes' width='100%' />
                  </Box>
               </Grid>
            </Container>
         </Box>

         <Box className={classes.bgAzul} pt={20} pb={10} px={{ xs: 4, md: 0 }} id='contatos'>
            <Container>
               <Grid container justifyContent='space-between'>
                  <Grid item xs={12} md={3}>
                     <Box mb={{ xs: 8, md: 0 }}>
                        <img src={Logo} alt='Logo' />
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <Box mb={{ xs: 8, md: 0 }}>
                        <Typography variant='h5' component='h5' className={classes.white}>Contatos</Typography>
                        {/* <Link className={classes.menuItem} href='#' target='_blank'>
                        <WhatsApp className={classes.iconRedes} /> +55 (21) 98765-4321
                     </Link> */}
                        <Link className={classes.menuItem} href='tel:552121846378' target='_blank'>
                           <Phone className={classes.iconRedes} /> +55 (21) 2184-6378
                        </Link>
                        <a className={classes.menuItem} href="mailto:comercial@axem.com.br">
                           <Mail className={classes.iconRedes} /> comercial@axem.com.br
                        </a>
                        <Box className={classes.menuItem}>
                           <Box float='left' mb='2rem'><Place className={classes.iconRedes} /></Box>
                           Rua do Passeio, 38 Torre 02 Sala 1501<br />
                           Setor 02 - Centro - Rio de Janeiro - RJ
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                     <Box mb={{ xs: 8, md: 0 }}>
                        <Typography variant='h5' component='h5' className={classes.white}>Institucional</Typography>
                        <Link className={classes.menuItem} href='/#quem-somos' to="quem-somos" smooth={true} spy={true} duration={600}>Quem Somos</Link>
                        <Link className={classes.menuItem} href='/#servicos' to="servicos" smooth={true} spy={true} duration={700}>Serviços</Link>
                        <Link className={classes.menuItem} href='/#corpo-tecnico' to="corpo-tecnico" smooth={true} spy={true} duration={700}>Corpo técnico</Link>
                        <Link className={classes.menuItem} href='/#clientes' to="clientes" smooth={true} spy={true} duration={700}>Clientes</Link>
                        <Link className={classes.menuItem} href='/#contatos' to="contatos" smooth={true} spy={true} duration={900}>Contatos</Link>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                     <Box mb={{ xs: 8, md: 0 }}>
                        <Typography variant='h5' component='h5' className={classes.white}>Redes Sociais</Typography>
                        <Link className={classes.menuItem} href='https://www.facebook.com/axem.com.br' target='_blank'>
                           <Facebook className={classes.iconRedes} /> Facebook
                        </Link>
                        <Link className={classes.menuItem} href='https://www.instagram.com/axem.com.br/' target='_blank'>
                           <Instagram className={classes.iconRedes} /> Instagram
                        </Link>
                        <Link className={classes.menuItem} href='https://www.linkedin.com/company/axemeng/' target='_blank'>
                           <LinkedIn className={classes.iconRedes} /> Linkedin
                        </Link>
                     </Box>
                  </Grid>
               </Grid>
               <hr className={classes.hr} />
               <Grid container justifyContent='space-between'>
                  <Grid item>
                     <Typography variant='body1' component='p' className={classes.white}>
                        © 2022 AXEM Engenharia e Arquitetura - Todos os direitos reservados
                     </Typography>
                  </Grid>
                  <Grid item>
                     <Typography variant='body1' component='span' className={classes.white}>
                        Desenvolvido por
                     </Typography>
                     <Link href='https://www.cwa.digital/' target='_blank' className={`${classes.white} ${classes.fontBold}`}> Cwa Digital</Link>
                  </Grid>
               </Grid>
            </Container>
         </Box>

      </Grid>
   );
}