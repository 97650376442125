import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   box: {
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      position: 'relative',
      height: '100%',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.06)',
   },
   white: {
      color: theme.palette.common.grey,
   },
   // icon: {
   //    color: theme.palette.primary.main,
   //    fontSize: '1rem',
   // },
}));

function Card(props) {
   const classes = useStyles();
   return (
      <Grid item xs={12} md={props.col} className={props.customizeBox}>
         <Grid container className={classes.box}>
            <Box p={8} textAlign='left'>
               <Box>
                  <Typography variant='h3' className={props.classHighlighter}>
                     <Grid container alignItems='center'>
                        {/* <Box display='flex' pr={4}>{props.icon}</Box> */}
                        <Box display='flex'>
                           {props.text}
                        </Box>
                     </Grid>
                  </Typography>
               </Box>
               <Box pt={0}>
                  <Typography variant='body1' component='p' className={classes.grey}>{props.text2}</Typography>
               </Box>
            </Box>
         </Grid>
      </Grid>
   );
}

export default Card;