import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Container, Box } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// Images
import Bullets from '../assets/images/bullets.webp';
import ImageCampanha from '../assets/images/hospital-campanha.webp';
import ImageUDI from '../assets/images/hospital-udi.webp';
import ImageClaro from '../assets/images/claro.webp';
import ImagePerinatal from '../assets/images/perinatal-laranjeiras.webp';
import ImageCopaDor from '../assets/images/copa-dor.webp';
import ImageNorteDor from '../assets/images/norte-dor.webp';

import ImageHospitalLagoa from '../assets/images/hospital-lagoa.webp';
import ImageBiblioteca from '../assets/images/biblioteca-nacional.webp';
import ImageVilaCarnauba from '../assets/images/vila-carnauba.webp';
import ImageFiocruz from '../assets/images/fiocruz.webp';
import { Grid } from '@material-ui/core';

const tutorialSteps = [
   {
      label: 'Projeto de consultoria, fiscalização e implantação de hospital de campanha no RJ',
      imgPath: `${ImageCampanha}`,
      description: 'Consultoria e fiscalização do projeto e implantação de hospital de campanha no RJ com 160 leitos e construído em menos de 20 dias.',
   },
   {
      label: 'Projeto de expansão do hospital UDI',
      imgPath: `${ImageUDI}`,
      description: 'Consultoria para desenvolvimento de projeto de torre hospitalar, sendo o sistema de expansão indireta com condensação a ar, totalizando 1400 TR, solução de chillers em série, tratamento de ar esterno com desacoplamento de calor sensível e latente e conformidade com a NBR 7256 (2021).',
   },
   {
      label: 'Projeto para a Claro',
      imgPath: `${ImageClaro}`,
      description: 'Desenvolvimento de projeto executivo em plataforma BIM para site de telecomunicações composto por sistema de expansão indireta com condensação a ar totalizando 160 TR.',
   },
   {
      label: 'Obra: Perinatal Laranjeiras',
      imgPath: `${ImagePerinatal}`,
      description: 'Executivo de Arquitetura e acompanhamento de obra, projeto com adequação de rotas de fuga, áreas de refúgio e mobilização de áreas administrativas, áreas de atendimento de emergência, centro cirúrgico e internação. Área Total: 2.738,36m²',
   },
   {
      label: 'Obra: Copa D’or',
      imgPath: `${ImageCopaDor}`,
      description: 'Executivo de arquitetura e acompanhamento de obra, projeto com adequação das áreas de refúgio e apoio. Área Total: 1.160,17m²',
   },
   {
      label: 'Obra: Norte D’or',
      imgPath: `${ImageNorteDor}`,
      description: 'Executivo de Arquitetura e acompanhamento de obra, projeto com mobilização de área de resíduos, emergência pediátrica com modernização de fachada, criação de novos leitos de internação. Área Total: 1.700,00m²',
   },
   {
      label: 'Centro Educacional Primeiro Mundo – Vila Velha, ES',
      // imgPath: `${}`,
      description: 'Desenvolvimento de estudos preliminares, projeto básico e executivo para as instalações prediais de hidrossanitárias, gás e elétrica do estabelecimento de ensino Primeiro Mundo no município de Vila Velha, ES. Área total de aproximadamente 7.000m²',
   },
   {
      label: 'Hospital Federal da Lagoa',
      imgPath: `${ImageHospitalLagoa}`,
      description: 'Desenvolvimento de estudos preliminares, projeto básico e projeto executivo para a Adequação das Instalações Elétricas e SPDA e Aterramento de todas as edificações e instalações do Complexo do Hospital Federal da Lagoa compreendendo uma área construída de aproximadamente de 25.508,00m² e com uma potência nominal instalada de 4,0MVA.',
   },
   {
      label: 'Biblioteca do Museu Nacional',
      imgPath: `${ImageBiblioteca}`,
      description: 'Acompanhamento técnico à Obra de Reforma da Biblioteca do Museu Nacional no Horto da Quinta da Boa Vista em São Cristóvão no Rio de Janeiro com todas as orientações e tratativas técnicas para a montagem eletromecânica para o atendimento das instalações elétricas de média e baixa tensão, SPDA e Aterramento. Área construída aproximada de 7.000,00m² com uma potência nominal de 800,00kVA.',
   },
   {
      label: 'Vila Carnaúba – Cruz, CE – Cliente FLOW CITY / Pivô Desenvolvimento Imobiliário',
      imgPath: `${ImageVilaCarnauba}`,
      description: 'Desenvolvimento de estudos preliminares, projeto básico e projeto executivo para a rede elétrica de média e baixa tensão, SPDA e Aterramento, Iluminação Pública e Infraestrutura seca de telecomunicações do empreendimento Vila Carnaúba composto por um condomínio de alto luxo de 300 (trezentos) lotes, área de lazer, hotel de alto padrão e área de utilidades, a ser construído no município de Cruz, no Ceará. Área de projeção do terreno de aproximadamente 400.000,00m², com uma potência nominal demandada na ordem de 2,5MVA. Projeto com aprovação junto a Concessionária de Energia ENEL Ceará.',
   },
   {
      label: 'FIOCRUZ RONDÔNIA – Cliente Ministério da Saúde / CONCREMAT Engenharia',
      imgPath: `${ImageFiocruz}`,
      description: 'Consultoria de Engenharia Elétrica e Eletrônica para o Gerenciamento e Fiscalização dos Projetos para a Construção do Empreendimento composto por edificações de Laboratórios e de Ensino e Pesquisas, incluindo todas as edificações de utilidades necessárias para o funcionamento destas edificações. Área construída aproximada de 7.500,00m² com uma potência instalada de aproximadamente 3,0MVA.',
   },
];

const useStyles = makeStyles((theme) => ({
   root: {
      // maxWidth: 400,
      // height: '555px',
      flexGrow: 1,
   },
   tag: {
      color: '#738292',
      marginTop: '0.5rem',
      marginRight: '0.75rem',
      padding: '0.5rem 1.5rem',
      border: '1px dashed #738292',
      borderRadius: '25px',
      fontSize: '0.85rem',
      display: 'inline-block',
   },
   mobileStepper: {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      marginTop: '4rem',
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
         marginTop: '1rem',
      },
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      padding: '0.75rem 4rem',
      marginTop: '3rem',
      color: 'white',
      borderRadius: '4px',
      fontWeight: 'bold',
      boxShadow: '0px 6px 30px rgba(85, 51, 255, 0.4)',
      backgroundColor: '#5533FF',
      '&:hover': {
         backgroundColor: '#5533FF',
         opacity: '0.9'
      },
   },
   img: {
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
   boxHeight: {
      // minHeight: '90px',
   },
}));

export default function TextMobileStepper() {
   const classes = useStyles();
   const theme = useTheme();
   const [activeStep, setActiveStep] = React.useState(0);
   const maxSteps = tutorialSteps.length;

   const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };

   return (
      <div className={classes.root}>
         <Container>
            <Grid container>
               <Box display='flex' flexDirection={{ xs: 'column-reverse', sm: 'row' }} justifyContent='space-between' mb={14}>
                  <Grid item md={4}>
                     <Box mt={{ xs: 16, md: -28 }}>
                        <img
                           className={classes.img}
                           src={tutorialSteps[activeStep].imgPath}
                           alt={tutorialSteps[activeStep].label}
                        />
                     </Box>
                  </Grid>
                  <Grid item md={7}>
                     <Box color='white' mt={{ xs: 8, md: 20}} mb={8}>
                        <Grid md={10}>
                           <Box mb={4}><img src={Bullets} alt='Bullets' /></Box>
                           <Typography variant='h2'>{tutorialSteps[activeStep].label}</Typography>
                        </Grid>
                     </Box>
                     <Box color='white' mb={8} className={classes.boxHeight}>
                        <Grid md={10}>
                           <Typography variant='body1' className={classes.white}>
                              {tutorialSteps[activeStep].description}
                           </Typography>
                        </Grid>
                     </Box>
                     <MobileStepper
                        className={classes.mobileStepper}
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                           <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.white}>
                              Próximo
                              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                           </Button>
                        }
                        backButton={
                           <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.white}>
                              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                              Voltar
                           </Button>
                        }
                     />
                  </Grid>
               </Box>
            </Grid>
         </Container>
      </div>
   );
}
